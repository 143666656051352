import { memo } from "react";
import Modal from "react-awesome-modal";

import Button from "components/button";
import flowerFamGif from "assets/graphics/FlowerFam—gifs.gif";

export default memo(function NoFlowerModal({ visible, onClose }) {
  return (
    <div className="modal">
      <Modal
        width="380"
        effect="fadeInUp"
        visible={visible}
        onClickAway={() => onClose()}
      >
        <div className="p-4 text-center">
          <img alt="Flower" src={flowerFamGif} className="rounded-2xl m-auto" />
          <div className="px-6 pt-6">
            <p className="font-morh-sb text-2xl mb-1">You shall not pass!</p>
            <p className="text-lg font-morh-m leading-tight">
              Entrance is granted to those who are worthy of a flower.
            </p>
          </div>
          <Button
            isPrimary
            classes={["relative", "bottom-[-40px]", "mt-[-20px]"]}
          >
            Buy one on Opensea
          </Button>
        </div>
      </Modal>
    </div>
  );
})
