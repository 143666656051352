import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { ProviderProvider } from "./context/provider";
import { providerReducer, initialState as provider } from "./reducers/provider";

import { AlertProvider } from "./context/alert";
import {
  alertReducer,
  initialState as alertInitialState,
} from "./reducers/alert";

import { FlowersWithBeesProvider } from "./context/flowersWithBees";
import {
  flowersWithBeesReducer,
  initialState as flowersWithBees,
} from "./reducers/flowersWithBees";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <FlowersWithBeesProvider
    initialState={flowersWithBees}
    reducer={flowersWithBeesReducer}
  >
    <ProviderProvider initialState={provider} reducer={providerReducer}>
      <AlertProvider initialState={alertInitialState} reducer={alertReducer}>
        <App />
      </AlertProvider>
    </ProviderProvider>
  </FlowersWithBeesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
