export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const isEmptyObj = (obj) => {
  for (const key in obj) {
    if (
      obj[key] !== null &&
      obj[key] !== "" &&
      obj[key] !== false &&
      obj[key] !== undefined
    )
      return false;
  }
  return true;
};

export const groupByKey = (array, key) => {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
};
