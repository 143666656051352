import { memo } from "react";
import Modal from "react-awesome-modal";

export default memo(function AlertModal({ visible, message, onClose }) {
  return (
    <div className="modal">
      <Modal
        width="380"
        effect="fadeInUp"
        visible={visible}
        onClickAway={() => onClose()}
      >
        <div className="p-8 text-center text-textColor">
          <p className="font-morh-sb text-3xl">
            <span className="text-red-600">Whoops,</span> that didn't go through
          </p>
          <p className="text-lg font-morh-m leading-tight mt-6">{message}</p>
        </div>
      </Modal>
    </div>
  );
});
