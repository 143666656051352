export default class NewGenFlower {
  constructor(instance, address) {
    this.address = address;
    this.instance = instance;
  }

  getTokens() {
    return this.instance.getNFTs(this.address);
  }

  getTokenDetail(tokenId) {
    return this.instance.newGenFlowerSpecs(tokenId);
  }

  balanceOf() {
    return this.instance.balanceOf(this.address);
  }
}
