export const initialState = null;

const SET_ALERT = "SET_ALERT";

export const setAlert = (data) => {
  return {
    payload: data,
    type: SET_ALERT,
  };
};

export const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        detail: action.payload,
      };
    default:
      return { detail: state };
  }
};

export default alertReducer;
