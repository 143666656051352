import { lazy } from "react";

import AppLayout from "layout";

import oasisBgImg from "assets/graphics/oasis-bg.jpg";
import marketBgImg from "assets/graphics/market-bg.jpg";
import checkerBgImg from "assets/graphics/checker-bg.jpg";

export const INDEX_ROUTE = "/";
export const BANK_ROUTE = "/bank/";
// export const OASIS_ROUTE = "/oasis/";
export const GARDEN_ROUTE = "/garden/";
export const MARKET_ROUTE = "/market/";
export const CHECKER_ROUTE = "/checker/";

// const IndexComponent = lazy(() => import("pages"));
const BankComponent = lazy(() => import("pages/bank"));
const OasisComponent = lazy(() => import("pages/oasis"));
const GardenComponent = lazy(() => import("pages/garden"));
const MarketComponent = lazy(() => import("pages/market"));
const CheckerComponent = lazy(() => import("pages/checker"));

export const ROUTES = [
  {
    path: "/",
    name: "index",
    component: AppLayout,
    routes: [
      {
        exact: true,
        name: "index",
        path: INDEX_ROUTE,
        component: OasisComponent,
      },
      {
        exact: true,
        name: "bank",
        path: BANK_ROUTE,
        bgImg: marketBgImg,
        component: BankComponent,
      },
      // {
      //   exact: true,
      //   name: "Oasis",
      //   path: OASIS_ROUTE,
      //   bgImg: oasisBgImg,
      //   component: OasisComponent,
      // },
      {
        exact: true,
        name: "Garden",
        path: GARDEN_ROUTE,
        bgImg: marketBgImg,
        component: GardenComponent,
      },
      {
        exact: true,
        name: "Market",
        path: MARKET_ROUTE,
        bgImg: marketBgImg,
        component: MarketComponent,
      },
      {
        exact: true,
        name: "Checker",
        bgImg: checkerBgImg,
        path: CHECKER_ROUTE,
        component: CheckerComponent,
      },
    ],
  },
];
