import { useEffect } from "react";

import Button from "components/button";
import ProviderHOC from "hoc/provider";
import useConnect from "hooks/useConnect";

function WalletConnectBtn({ provider, setProvider, children }) {
  const [, isConnecting, doConnect, connection] = useConnect(provider);

  useEffect(() => {
    setProvider(connection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  return (
    <Button onClick={() => doConnect()} loading={isConnecting}>
      {children}
    </Button>
  );
}

export default ProviderHOC(WalletConnectBtn);
