import { matchPath } from "react-router-dom";
import { memo, useEffect, useState } from "react";

import { INDEX_ROUTE } from "routes";

import Header from "components/header";
import Button from "components/button";
import skyImg from "assets/graphics/sky.jpg";
import RenderRoutes from "components/renderRoutes";
import NoFlowerModal from "components/noFlowerModal";

import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as CloudSvg } from "assets/graphics/cloudBig.svg";
import { ReactComponent as NoticeSvg } from "assets/graphics/notice.svg";

function getScreenWidth() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

export default memo(function AppLayout({
  appLoading,
  flowersCount,
  routes,
  location,
  history,
}) {
  const [bgImg, setBgImg] = useState();
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [noFlowerModalVisible, setNoFlowerModalVisible] = useState(false);

  useEffect(() => {
    if (!appLoading && !flowersCount) {
      setNoFlowerModalVisible(true);
    }
  }, [appLoading, flowersCount]);

  useEffect(() => {
    let newBgImg = null;

    routes.forEach((item) => {
      const matchedPath = matchPath(location.pathname, {
        exact: true,
        path: item.path,
      });
      if (matchedPath) {
        newBgImg = item.bgImg;
      }
    });

    setBgImg(newBgImg);
  }, [location.pathname, routes]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        setScreenWidth(getScreenWidth());
      },
      true
    );
  });

  return (
    <>
      <div
        className="bg-cover bg-no-repeat bg-fixed"
        style={{ backgroundImage: `url(${bgImg || skyImg})` }}
      >
        {screenWidth > 1024 ? (
          <div className="pt-10 pb-0 px-6 container m-auto flex flex-col min-h-screen">
            <Header
              hasFlowers={!appLoading && flowersCount}
              showNoFlowerModal={() => setNoFlowerModalVisible(true)}
            />
            <main className="flex-1 flex flex-col">
              <RenderRoutes routes={routes} />
            </main>
          </div>
        ) : (
          <div className="pt-10 pb-0 px-4 container m-auto flex flex-col min-h-screen justify-between">
            <header className="flex items-center bg-white relative rounded-full justify-between py-4 px-6 self-center w-full">
              <Logo
                className="mr-12 cursor-pointer"
                onClick={() => history.push(INDEX_ROUTE)}
              />
            </header>
            <div className="relative">
              <NoticeSvg className="m-auto" />
              <p className="font-morh-sb gap-1 items-center absolute max-w-[240px] flex-col bottom-[200px] left-1/2 ml-[-120px] text-center rotate-[-3deg]">
                <span className="font-morh-sb">
                  The Oasis is not working&nbsp;
                </span>
                <span>on the your mobile phone because&nbsp;</span>
                <span className="font-morh-sb">
                  of Metamask... Try your Dekstop!
                </span>
              </p>
              <Button
                classes={[
                  "absolute",
                  "bottom-[90px]",
                  "left-1/2",
                  "ml-[-100px]",
                ]}
              >
                Read our gitbook
              </Button>
            </div>
          </div>
        )}
      </div>
      {screenWidth > 1024 ? (
        <CloudSvg className="w-full mt-[-10%] z-10 relative" />
      ) : (
        ""
      )}
      <NoFlowerModal visible={noFlowerModalVisible} onClose={() => {}} />
    </>
  );
});
