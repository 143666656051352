import { useHistory } from "react-router-dom";

import * as routes from "routes";

import Button from "components/button";
import ProviderHOC from "hoc/provider";
import WalletConnectBtn from "components/walletConnectBtn";

import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as Medium } from "assets/graphics/medium.svg";
import { ReactComponent as Discord } from "assets/graphics/discord.svg";
import { ReactComponent as Twitter } from "assets/graphics/twitter.svg";
import { ReactComponent as Instagram } from "assets/graphics/instagram.svg";

function Header({ hasFlowers, showNoFlowerModal, isConnected }) {
  const history = useHistory();

  function onLinkItemClick(to) {
    if (hasFlowers) {
      history.push(to);
    } else {
      showNoFlowerModal();
    }
  }

  return (
    <header className="flex items-center bg-white relative rounded-full justify-between py-4 px-6 self-center w-full">
      <div className="flex items-center">
        <Logo
          className="mr-12 cursor-pointer"
          onClick={() => onLinkItemClick(routes.INDEX_ROUTE)}
        />

        <ul className="flex text-lg font-morh-b gap-6 mr-8 leading-none items-center">
          <li
            className="hover:opacity-50 cursor-pointer"
            onClick={() => onLinkItemClick(routes.GARDEN_ROUTE)}
          >
            Your Garden
          </li>
          <li
            className="hover:opacity-50 cursor-pointer"
            onClick={() => onLinkItemClick(routes.BANK_ROUTE)}
          >
            Bank
          </li>
          <li
            className="hover:opacity-50 cursor-pointer"
            onClick={() => onLinkItemClick(routes.MARKET_ROUTE)}
          >
            Market
          </li>
          <li
            className="hover:opacity-50 cursor-pointer"
            onClick={() => onLinkItemClick(routes.CHECKER_ROUTE)}
          >
            Checker
          </li>
          <li className="hover:opacity-50">
            <a href="#faq">FAQ</a>
          </li>
        </ul>
      </div>
      <div className="flex items-center gap-6">
        <div className="flex gap-4">
          <Button classes={["w-[36px]", "h-[36px]", "!p-0", "justify-center"]}>
            <Medium />
          </Button>
          <Button classes={["w-[36px]", "h-[36px]", "!p-0", "justify-center"]}>
            <Discord />
          </Button>
          <Button classes={["w-[36px]", "h-[36px]", "!p-0", "justify-center"]}>
            <Twitter />
          </Button>
          <Button classes={["w-[36px]", "h-[36px]", "!p-0", "justify-center"]}>
            <Instagram />
          </Button>
        </div>
        {isConnected ? (
          <Button isPrimary classes={["uppercase", "!px-10"]}>
            Connected
          </Button>
        ) : (
          <WalletConnectBtn>Connect wallet</WalletConnectBtn>
        )}
      </div>
    </header>
  );
}

export default ProviderHOC(Header);
