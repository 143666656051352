import Species from "proofs/species.json";

export default class Ecosystem {
  constructor(instance, address) {
    this.address = address;
    this.instance = instance;
  }

  getMerkleProof(flowerId) {
    return Species.proofs[flowerId];
  }

  getMerkleProofs(flowerIds) {
    const raw = Species.proofs;
    const proofs = Object.keys(raw)
      .filter((key) => flowerIds.includes(key))
      .reduce((obj, key) => {
        obj[key] = raw[key];
        return obj;
      }, {});
    return Object.values(proofs);
  }

  getUserProduction() {
    return this.instance.userToProductionInfo(this.address);
  }

  getNotAccumulatedUserProduction(flowersWithBees) {
    return this.instance.getTotalNotAccumulatedProductionOfUser(
      this.address,
      flowersWithBees
    );
  }

  getUserTotalHoneyCount(flowersWithBees) {
    return this.instance.getTotalProductionOfUser(
      this.address,
      flowersWithBees
    );
  }

  getFlowerAttachedBee(flowerId) {
    return this.instance.flowerToBee(flowerId);
  }

  getNewGenFlowerAttachedBee(flowerId) {
    return this.instance.newGenFlowerToBee(flowerId);
  }

  getAttachedFlower(beeId) {
    return this.instance.getAttachedFlowerOfBee(beeId);
  }

  getAttachedNewGenFlower(beeId) {
    return this.instance.getAttachedNewGenFlowerOfBee(beeId);
  }

  getAttachedFlowers() {
    return this.instance.getFlowerFamFlowersOfUserWithBees(this.address);
  }

  getAttachedNewGenFlowers() {
    return this.instance.getNewGenFlowersOfUserWithBees(this.address);
  }

  claimNotAccumulatedUserProduction(signer, flowersWithBees) {
    return this.instance
      .connect(signer)
      .claimTotalNotAccumulatedProductionOfUser(this.address, flowersWithBees);
  }

  stakeFlower(signer, flowerId) {
    return this.instance
      .connect(signer)
      .stakeFlowerFamFlower(flowerId, ...this.getMerkleProof(flowerId));
  }

  unstakeFlower(signer, flowerId) {
    return this.instance
      .connect(signer)
      .unstakeFlowerFamFlower(flowerId, ...this.getMerkleProof(flowerId));
  }

  batchStakeFlowers(signer, flowerIds) {
    const proofs = this.getMerkleProofs(flowerIds);
    return this.instance.connect(signer).batchStakeFlowerFamFlowers(
      flowerIds,
      proofs.map((i) => i[0]),
      proofs.map((i) => i[1])
    );
  }

  batchUnstakeFlowers(signer, flowerIds) {
    const proofs = this.getMerkleProofs(flowerIds);
    return this.instance.connect(signer).batchUnstakeFlowerFamFlower(
      flowerIds,
      proofs.map((i) => i[0]),
      proofs.map((i) => i[1])
    );
  }

  stakeNewGenFlower(signer, flowerId) {
    return this.instance.connect(signer).stakeNewGenerationFlower(flowerId);
  }

  unstakeNewGenFlower(signer, flowerId) {
    return this.instance.connect(signer).unstakeNewGenerationFlower(flowerId);
  }

  batchStakeNewGenFlowers(signer, flowerIds) {
    return this.instance
      .connect(signer)
      .batchStakeNewGenerationFlower(flowerIds);
  }

  batchUnstakeNewGenFlowers(signer, flowerIds) {
    return this.instance
      .connect(signer)
      .batchUnstakeNewGenerationFlower(flowerIds);
  }

  attachBee(signer, beeId, flowerId) {
    return this.instance.connect(signer).attachBeeToFlower(flowerId, beeId);
  }

  releaseBee(signer, beeId, flowerId) {
    return this.instance.connect(signer).releaseBeeFromFlower(flowerId, beeId);
  }

  attachBeeToNewGenFlower(signer, beeId, flowerId) {
    return this.instance
      .connect(signer)
      .attachBeeToNewGenFlower(flowerId, beeId);
  }

  releaseBeeToNewGenFlower(signer, beeId, flowerId) {
    return this.instance
      .connect(signer)
      .releaseBeeFromNewGenFlower(flowerId, beeId);
  }
}
