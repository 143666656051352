/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { ROUTES } from "routes";
import { isEmpty } from "_dash";
import { CONTRACTS } from "global_constants";

import AlertHOC from "hoc/alert";
import ProviderHOC from "hoc/provider";
import FlowerFam from "adpaters/flower";
import useConnect from "hooks/useConnect";
import useProvider from "hooks/useProvider";
import AlertModal from "components/alertModal";
import NewGenFlower from "adpaters/newGenFlower";
import FlowersWithBeesHOC from "hoc/flowersWithBees";
import RouteWithSubRoutes from "components/routeWithSubRoutes";

function App({
  provider,
  setProvider,
  alertMsg,
  alertVisible,
  hideAlert,
  getFlowerWithBees,
}) {
  const contracts = useProvider(provider);
  const [, , , connection, doAutoConnect] = useConnect(provider);

  const [loading, setLoading] = useState(true);
  const [flowersCount, setFlowersCount] = useState(0);

  useEffect(() => {
    doAutoConnect();
  }, []);

  useEffect(() => {
    if (connection) {
      setProvider(connection);
    }
  }, [connection]);

  useEffect(() => {
    if (!isEmpty(contracts)) {
      getFlowerWithBees(contracts[CONTRACTS.ECOSYSTEM], provider.address);
      (async function () {
        const flowerObj = new FlowerFam(
          contracts[CONTRACTS.FLOWER],
          provider.address
        );
        const newGenFlowerObj = new NewGenFlower(
          contracts[CONTRACTS.NEWGENFLOWER],
          provider.address
        );
        const flowerBalance = await flowerObj.balanceOf();
        const newGenFlowerBalance = await newGenFlowerObj.balanceOf();

        setFlowersCount(
          parseInt(newGenFlowerBalance) + parseInt(flowerBalance)
        );
        setLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts]);

  return (
    <>
      <Router>
        <Switch>
          {ROUTES.map((route, i) => {
            return (
              <RouteWithSubRoutes
                key={i}
                {...route}
                appLoading={loading}
                flowersCount={flowersCount}
              />
            );
          })}
        </Switch>
      </Router>
      <AlertModal
        message={alertMsg}
        visible={alertVisible}
        onClose={() => hideAlert()}
      />
    </>
  );
}

export default FlowersWithBeesHOC(ProviderHOC(AlertHOC(App)));
