/*eslint-disable*/
import React, { useReducer, useContext, createContext } from "react";

const FlowersWithBeesContext = createContext();

export const FlowersWithBeesProvider = ({
  reducer,
  initialState,
  children,
}) => {
  const contextValue = useReducer(reducer, initialState);
  return (
    <FlowersWithBeesContext.Provider value={contextValue}>
      {children}
    </FlowersWithBeesContext.Provider>
  );
};

export const FlowersWithBeesConsumer = () => {
  const contextValue = useContext(FlowersWithBeesContext);
  return contextValue;
};
