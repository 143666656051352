export const NETWORK = {
  TARGET_CHAIN_ID: 42,
  MAINNET_CHAIN_ID: 1,
  CHAIN_ID_TO_NAME: {
    1: "mainnet",
    3: "ropsten",
    4: "rinkeby",
    42: "kovan",
    1337: "localhost",
  },
  INFURA_ID: "632b4850f79f4f7f9ba2468897bdcbc0",
};

export const CONTRACTS = {
  BEE: "Bee",
  HONEY: "Honey",
  FLOWER: "FlowerFam",
  ECOSYSTEM: "FlowerFamEcoSystem",
  NEWGENFLOWER: "FlowerFamNewGen",
  MARKETPLACE: "FlowerFamMarketPlace",
};

export const MORALIS = {
  CREDS: [
    {
      APP_ID: "l2dkcQuvB1vvInnA99a03SxpvM5XotUXNwUHtBKu",
      SERVER_URL: "https://chnjzednf0ro.usemoralis.com:2053/server",
    },
    {
      APP_ID: "KyBtbDOP2kTksGt0wk1heHUrxl0mJqfdqXVOEYeK",
      SERVER_URL: "https://wr39z5rslhh3.usemoralis.com:2053/server",
    },
    {
      APP_ID: "BPsfbad1ZEdVAnMkJmCs5Me2Gd4UnX2zH66aBF4Q",
      SERVER_URL: "https://pjaxjcjmxfmy.usemoralis.com:2053/server",
    },
    {
      APP_ID: "AjhoycArT2HHMHR1WmnUEiApJk3Q1YYeK1QqeurM",
      SERVER_URL: "https://9tb5e0nuzwrj.usemoralis.com:2053/server",
    },
  ],
};

export const FLOWER_FAM_MAPPING = {
  0: {
    name: "Prodigies",
    coins_per_day: 5,
  },
  1: {
    name: "Seedlings",
    coins_per_day: 7,
  },
  2: {
    name: "Ancestors",
    coins_per_day: 12,
  },
  3: {
    name: "Elders",
    coins_per_day: 20,
  },
  4: {
    name: "Pioneers",
    coins_per_day: 35,
  },
};

export const NEW_GEN_FLOWER = {
  name: "New gen",
  coins_per_day: 2.5,
};
