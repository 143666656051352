export const initialState = null;

const SET_PROVIDER = "SET_PROVIDER";

export const setProvider = (data) => {
  return {
    payload: data,
    type: SET_PROVIDER,
  };
};

export const providerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROVIDER:
      return {
        detail: action.payload,
      };
    default:
      return { detail: state };
  }
};

export default providerReducer;
