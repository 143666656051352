import React from "react";

import { AlertConsumer } from "context/alert";
import { setAlert as alertSetter } from "reducers/alert";

const AlertHOC = (BaseComponent) => (props) => {
  const [alertConsumer, dispatch] = AlertConsumer();
  const alertDetail = alertConsumer?.detail || {};

  function showAlert(msg) {
    dispatch(
      alertSetter({
        msg,
        visible: true,
      })
    );
  }

  function hideAlert() {
    dispatch(
      alertSetter({
        visible: false,
      })
    );
  }

  return (
    <BaseComponent
      {...props}
      alertMsg={alertDetail.msg}
      hideAlert={() => hideAlert()}
      alertVisible={alertDetail.visible}
      showAlert={(msg) => showAlert(msg)}
    />
  );
};

export default AlertHOC;
